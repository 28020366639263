import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";
import HeroWithImage from "../../components/heroWithImage/index";
import Seo from "../../components/seo";
import SedarFillings from "../../containers/investors/sedarFilligns";

import heroDesktop from "../../assets/heroDesktop/ReportsAndFilings.png";
import heroMobile from "../../assets/heroMobile/ReportsAndFilings.png";

import "./index.scss";

const CorporateDirectory = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="REPORTS AND FILINGS" />
      <HeroWithImage
        title={intl.locale === "fr" ? "RAPPORTS ET DOCUMENTS DEPOSES" : <>REPORTS &&nbsp;&nbsp;FILINGS</>}
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <SedarFillings data={data.documents.edges} />
    </Layout>
  );
};

export const query = graphql`
  query ReportsAndFilingsQuery($locale: String) {
    documents: allContentfulReportsDisclosuresDocuments(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          type
          date(formatString: "MMM DD, YYYY")
          link
          tags
        }
      }
    }
  }
`;

export default CorporateDirectory;
